import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import {
  contentWrapper,
  regionsImageWrapper,
  regionListContainer,
  regionsWrapper,
  regionsContainer,
  sectionWrapper
} from '../styles/workspace.module.css'

const RegionsSection = ({ regionsHeader, regionsContent, regionsMap }) => {
  return (
    <div>
      <div className={sectionWrapper}>
        <div className={regionsWrapper}>
          <div className={regionsContainer}>
            <h2>{regionsHeader}</h2>
            <div>
              {regionsContent.map((content, key) => {
                return (
                  <div
                    className={contentWrapper}
                    key={key.toString()}>
                    {parse(content.regionContentHeading)}
                    <span className={regionListContainer}>{parse(content.regionContentItems)}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={regionsImageWrapper}>
            <img src={regionsMap} />
          </div>
        </div>
      </div>
    </div>
  )
}

RegionsSection.propTypes = {
  regionsContent: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  regionsHeader: PropTypes.string.isRequired,
  regionsMap: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired
  ])
}

export default RegionsSection
