import React from 'react'
import PropTypes from 'prop-types'
import whatsappIcon from '../images/whatsappIcon.svg'
import {
  bookingButton,
  altBookingButton,
  buttonWrapper,
  buttonWrapperFlexColumn,
  spacer,
  altSpacer,
  whatsappButton,
  altWhatsappButton
} from '../styles/workspace.module.css'

const BookingCtas = ({ pageData, calendarUrl, isFlex, isColumn }) => {
  return (
    <div className={isFlex ? buttonWrapperFlexColumn : buttonWrapper}>
      <a
        className={isColumn ? altBookingButton : bookingButton}
        href={calendarUrl}
        target={'_blank'}
      >
        {pageData.gwBookSlotText}
      </a>
      <span className={isColumn ? altSpacer : spacer}>or</span>
      <a
        className={isColumn ? altWhatsappButton : whatsappButton}
        href={pageData.gwWhatsappLink}
        rel='noopener noreferrer'
        target={'_blank'}
      >
        <img src={whatsappIcon} />
        {pageData.gwWhatsappText}
      </a>
    </div>
  )
}

BookingCtas.propTypes = {
  calendarUrl: PropTypes.string.isRequired,
  isColumn: PropTypes.bool,
  isFlex: PropTypes.bool,
  pageData: PropTypes.object.isRequired
}

export default BookingCtas
