import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import parse from 'html-react-parser'
import BookingCtas from 'components/bookingCtas'
import ContentSection from 'components/contentSection'
import RegionsServed from 'components/regionsWeServe'
import CarouselComponent from 'components/carousel'
import BlockContentComponent from 'components/blockContentComponent'
import SocialLinks from 'components/socialLinks'
import quoteLeft from 'images/quote-left.svg'
import { graphql, Link } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { composeClasses, jsonId } from 'utils'
import { isMobile } from 'react-device-detect'
import Accordion from '../components/accordion'
import HelmetExport from 'react-helmet'
import * as sharedStyles from '../styles/sharedLayout.module.css'
import {
  headerSection,
  testimonialAuthor,
  testimonialAvatar,
  testimonialAuthorName,
  testimonialAuthorJobTitle,
  testimonialAuthorNameMobile,
  testimonialAuthorCountry
}
  from '../styles/careers.module.css'
import {
  gcpWrapper,
  headingWrapper,
  headingContainer,
  headingDescription,
  googlePartnerBadge,
  whyChooseDeimosSection,
  whyChooseDeimosSectionColumn,
  whyDeimosFlexContainer,
  contentDescriptionStyle,
  weDeliverSafetySection,
  certificationMobileImage,
  certificationImageDesktop,
  helpContentSection,
  helpContentSectionHyperlink,
  cloudServicesHyperlink,
  carouselBlock,
  cloudServicesSectionHeader,
  cloudServicesSection,
  cloudServicesInner,
  cloudServicesInnerItems,
  otherCloudServicesHyperLink,
  strategicPartnershipWrapper,
  strategicButtonStyles,
  strategicIconContainer,
  accordionSection
} from '../styles/googleCloudStyles.module.css'
import {
  googleWorkspaceWrapper,
  bookASlotWrapper,
  bookASlotBodyContainer,
  bookASlotContent,
  bookASlotSectionSubtitle,
  bookASlotCta,
  bookASlotCtaInner,
  customerSuccessWrapper,
  customerSuccessHeader,
  customerSuccessContainer,
  customerSuccessImageWrapper,
  paddedContainer,
  successStoryDesc,
  customerSuccessDivDesktop,
  customerSuccessDivMobile,
  customerSuccessButtonWrapper,
  customerSuccessButton,
  successListContainer,
  testimonialSection,
  customerCarousel
  ,
  faqSectionHeader
} from '../styles/workspace.module.css'
import {
  quoteStyleLeft,
  quoteStyleRight
} from '../styles/internship.module.css'
import { accordion } from '../styles/accordion.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "google cloud platform" }) {
        edges {
          node {
            googleCloudFieldGroup {
              googleCloudHeader
              googleCloudHeaderImage {
                sourceUrl
              }
              googleCloudDescription
              gwBookSlotText
              gwWhatsappText
              gwWhatsappLink
              whyChooseDeimosHeader
              whyChooseDeimosContent {
                whyChooseDeimosContentNumber
                whyChooseDeimosContentHeader
                whyChooseDeimosContentDescription 
              }
              bookASlotSectionHeader
              bookASlotSectionSubtitle
              bookASlotSection
              calendarUrl
              ourCertificationsHeader
              ourCertificationsDescription
              weDeliverSafetyHeader
              weDeliverSafetyContent
              ourCertificationsImage {
                sourceUrl
              }
               ourCertificationsImageMobile {
                sourceUrl
              }
              customerSuccessHeader
              customerSuccessIcon {
                sourceUrl
              }
              customerSuccessTitle
              customerSuccessSubtitle
              customerSuccessContent
              customerSuccessButtonText
              customerSuccessButtonLink {
                link
              }
              helpHeader
              helpContent {
                deimosHelpHeader
                deimosHelpDescription
                deimosHelpHyperlink
                deimosHelpHyperlinkText
              }
              customersTestimonialHeader
              customersTestimonialSection {
                customerTestimonialRole
                customerTestimonialQuote
                customerTestimonialName
                customerTestimonialImage {
                  sourceUrl
                }
              }
              otherCloudServicesHeader
              otherCloudServicesContent {
                otherCloudServicesNumber
                otherCloudServicesContent
              }
              otherCloudServicesCta
              otherCloudServicesCtaLink
              strategicPartnershipHeader
                strategicPartnershipDesktopIcon {
                  sourceUrl
                }
                strategicPartnershipMobileIcon {
                  sourceUrl
                }
              strategicPartnershipButtonText
              faqSectionHeader
              faqsContent {
                faqsContentNumber
                faqsContentHeading
                faqsContentDescription
              }
              regionsServedHeader
              regionsServedContent {
                regionContentHeading
                regionContentItems
              }
              regionsServedMap {
                sourceUrl
              }
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const GoogleCloud = ({ data, pageContext }) => {
  const cloudData = data.wordPress.pages.edges[0].node.googleCloudFieldGroup
  const gcpPartnerbadges = cloudData.googleCloudHeaderImage.sourceUrl
  const calendarUrl = cloudData.calendarUrl
  const whyChooseDeimosData = cloudData.whyChooseDeimosContent
  const whyChooseDeimosHeader = cloudData.whyChooseDeimosHeader
  const helpContent = cloudData.helpContent
  const customersTestimonial = cloudData.customersTestimonialSection
  const otherCloudServices = cloudData.otherCloudServicesContent
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const strategicIconDesktop = cloudData.strategicPartnershipDesktopIcon.sourceUrl
  const strategicIconMobile = cloudData.strategicPartnershipMobileIcon.sourceUrl
  const faqsContent = cloudData.faqsContent
  const regionsHeader = cloudData.regionsServedHeader
  const regionsContent = cloudData.regionsServedContent
  const regionsMap = cloudData.regionsServedMap.sourceUrl
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug

  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='This page explains Deimos’ relationship with Google Cloud Platform.'
        title='Google Cloud Platform'
      />
      <div className={googleWorkspaceWrapper}>
        <div className={gcpWrapper}>
          <Breadcrumb
            crumbLabel='Google Cloud Platform'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
          <div className={headingWrapper}>
            <div className={headingContainer}>
              <h1>{cloudData.googleCloudHeader}</h1>
              <div className={headingDescription}>{parse(cloudData.googleCloudDescription)}</div>
              <BookingCtas
                calendarUrl={calendarUrl}
                pageData={cloudData} />
            </div>
            <img
              alt='google partner badge'
              className={googlePartnerBadge}
              src={gcpPartnerbadges}
            />
          </div>
        </div>

        <section className={weDeliverSafetySection}>
          <div>
            <h2>{cloudData.weDeliverSafetyHeader}</h2>
            <div>{parse(cloudData.weDeliverSafetyContent)}</div>
          </div>
        </section>

        <div className={whyChooseDeimosSection}>
          <ContentSection
            backgroundColor={'#E5E5E5'}
            items={whyChooseDeimosData}
            render={(item, index) => {
              const { whyChooseDeimosContentDescription, whyChooseDeimosContentHeader, whyChooseDeimosContentNumber } = item
              return (
                <div
                  className={whyChooseDeimosSectionColumn}
                  key={index.toString()}
                >
                  <div className={whyDeimosFlexContainer}>
                    {parse(whyChooseDeimosContentNumber)}
                    <h5>{whyChooseDeimosContentHeader}</h5>
                  </div>
                  <span className={contentDescriptionStyle}>{parse(whyChooseDeimosContentDescription)}</span>
                </div>
              )
            }}
            title={whyChooseDeimosHeader}
          />
        </div>
        <div className={bookASlotWrapper}>
          <div className={bookASlotBodyContainer}>
            <div className={bookASlotContent}>
              {parse(cloudData.bookASlotSectionHeader)}
              <p className={bookASlotSectionSubtitle}>{cloudData.bookASlotSectionSubtitle}</p>
              <span>{parse(cloudData.bookASlotSection)}</span>
            </div>
            <div className={bookASlotCta}>
              <div className={bookASlotCtaInner}>
                <BookingCtas
                  calendarUrl={calendarUrl}
                  isColumn
                  isFlex
                  pageData={cloudData} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <BlockContentComponent
            backgroundColor={'var(--formFields)'}
            items={helpContent}
            render={(item, index) => {
              const { deimosHelpHeader, deimosHelpDescription, deimosHelpHyperlink, deimosHelpHyperlinkText } = item
              return (
                <div
                  className={helpContentSection}
                  key={index.toString()}>
                  <div>
                    <h4>{deimosHelpHeader}</h4>
                  </div>
                  {parse(deimosHelpDescription)}
                  <div className={helpContentSectionHyperlink}>
                    <Link
                      to={`/${deimosHelpHyperlink.toLowerCase()}`}>
                      {deimosHelpHyperlinkText}
                    </Link>
                  </div>
                </div>
              )
            }}
            title={cloudData.helpHeader}
          />
        </div>
        <div className={customerSuccessWrapper}>
          <h2 className={customerSuccessHeader}>
            {cloudData.customerSuccessHeader}
          </h2>
          <div className={customerSuccessContainer}>
            <a
              href={cloudData.customerSuccessButtonLink.link}
              rel='noopener noreferrer'
              target={'_blank'}>
              <div className={customerSuccessImageWrapper}>
                <img src={cloudData.customerSuccessIcon.sourceUrl} />
              </div>
              <div className={paddedContainer}>
                <div className={successStoryDesc}>
                  <h4>
                    {cloudData.customerSuccessTitle}
                  </h4>
                  <p>
                    {parse(cloudData.customerSuccessSubtitle)}
                  </p>
                </div>
                <div className={customerSuccessDivDesktop}>
                  <span className={successListContainer}>{parse(cloudData.customerSuccessContent)}</span>
                </div>
                <div className={customerSuccessDivMobile}>
                  <span className={successListContainer}>{parse(cloudData.customerSuccessContent.substring(0, 115) + '...')}</span>
                </div>
              </div>
            </a>
            <div className={customerSuccessButtonWrapper}>
              <span
                className={customerSuccessButton}>
                {cloudData.customerSuccessButtonText}
              </span>
            </div>
          </div>
        </div>
        <div>
          <RegionsServed
            regionsContent={regionsContent}
            regionsHeader={regionsHeader}
            regionsMap={regionsMap} />
        </div>
        <div>
          <div className={carouselBlock}>
            <div className={composeClasses(sharedStyles.verticalContainer, testimonialSection)}>
              <div className={composeClasses(sharedStyles.verticalContainerInner, headerSection)}>
                <h2>{cloudData.customersTestimonialHeader}</h2>
                <CarouselComponent
                  items={customersTestimonial}
                  itemsToShow={3}
                  render={(item, index) => {
                    const { customerTestimonialRole, customerTestimonialQuote, customerTestimonialName, customerTestimonialImage } = item
                    return (
                      <div
                        className={customerCarousel}
                        key={index.toString()}
                        style={isMobile && customersTestimonial.length < 3 ? { maxWidth: '100%' } : ''}
                      >
                        <div className={testimonialAuthor}>
                          <img
                            className={testimonialAvatar}
                            src={customerTestimonialImage && customerTestimonialImage.sourceUrl}
                          />
                          <div>
                            <span className={testimonialAuthorName}>{`${customerTestimonialName}`}</span>
                            <p className={testimonialAuthorJobTitle}>{customerTestimonialRole}</p>
                            <span className={testimonialAuthorNameMobile}><strong> {`${customerTestimonialName}`} </strong> </span>
                            <br className={testimonialAuthorNameMobile} />
                            <span className={testimonialAuthorCountry}>{customerTestimonialRole}</span>
                          </div>
                        </div>
                        <img
                          className={quoteStyleLeft}
                          src={quoteLeft}
                        />
                        {customerTestimonialQuote}
                        <img
                          className={quoteStyleRight}
                          src={quoteLeft}
                        />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={accordionSection}>
          <h2 className={faqSectionHeader}>{cloudData.faqSectionHeader}</h2>
          <div className={accordion}>
            {faqsContent.map((item, index) => (
              <Accordion
                content={parse(item.faqsContentDescription)}
                contentTitle={item.faqsContentHeading}
                key={index.toString()}
                numbers={item.faqsContentNumber}
              />
            ))}
          </div>
        </div>
        <div className={cloudServicesSection}>
          <div className={cloudServicesSectionHeader}>
            <h2>{cloudData.otherCloudServicesHeader}</h2>
          </div>
          <div className={cloudServicesInner}>
            {
              otherCloudServices.map((item, index) => {
                return (
                  <div
                    className={cloudServicesInnerItems}
                    key={index.toString()}>
                    <h6>{item.otherCloudServicesNumber}</h6>
                    <span>{parse(item.otherCloudServicesContent)}</span>
                  </div>
                )
              })
            }
          </div>
          <div className={composeClasses(cloudServicesHyperlink, otherCloudServicesHyperLink)}>
            <Link to={`/${cloudData.otherCloudServicesCtaLink.toLowerCase()}`}>{cloudData.otherCloudServicesCta}</Link>
          </div>
        </div>
        <div className={strategicPartnershipWrapper}>
          <h2>{cloudData.strategicPartnershipHeader}</h2>
          <div className={strategicIconContainer}>
            <img
              alt='GCP Strategic Partnership Icons'
              className={certificationImageDesktop}
              src={strategicIconDesktop}
            />
            <img
              alt='GCP Strategic Partnership Icons'
              className={certificationMobileImage}
              src={strategicIconMobile}
            />
          </div>
          <Link
            className={strategicButtonStyles}
            to={'/partnerships'}>
            {cloudData.strategicPartnershipButtonText}
          </Link>
        </div>
        <SocialLinks
          hasWhiteBackground
          socialLinks={footerData.socialLinks} />
      </div>
    </>
  )
}

GoogleCloud.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default GoogleCloud
