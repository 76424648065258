import React from 'react'
import PropTypes from 'prop-types'
import {
  sectionWrapper,
  sectionTitle,
  section,
  blockSection,
  professionalSectionTitle
} from 'styles/blockContent.module.css'

const BlockContentComponent = ({ items, render, title, backgroundColor, professionalServices, leadText }) => {
  return (
    <div
      className={sectionWrapper}
      style={{
        backgroundColor: backgroundColor
      }}>
      <div className={professionalServices ? professionalSectionTitle : sectionTitle}>
        {professionalServices ? <h5>{leadText}</h5> : ''}
        <h2>{title}</h2>
      </div>
      <div className={blockSection}>
        <div className={section}>
          {render && items.map((item, index) => {
            return render(item, index)
          })
          }
        </div>
      </div>
    </div>
  )
}

BlockContentComponent.propTypes = {
  backgroundColor: PropTypes.string,
  items: PropTypes.array.isRequired,
  leadText: PropTypes.string,
  professionalServices: PropTypes.bool,
  render: PropTypes.func,
  title: PropTypes.string.isRequired
}

export default BlockContentComponent
