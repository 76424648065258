// extracted by mini-css-extract-plugin
export var gcpWrapper = "googleCloudStyles-module--gcpWrapper--fYA+2";
export var weDeliverSafetySection = "googleCloudStyles-module--weDeliverSafetySection--adK3X";
export var headingWrapper = "googleCloudStyles-module--headingWrapper--LkRwK";
export var headingContainer = "googleCloudStyles-module--headingContainer--l0OD8";
export var headingDescription = "googleCloudStyles-module--headingDescription--IcECc";
export var googlePartnerBadge = "googleCloudStyles-module--googlePartnerBadge--Lgf4a";
export var whyChooseDeimosSection = "googleCloudStyles-module--whyChooseDeimosSection--EupJ4";
export var whyChooseDeimosSectionColumn = "googleCloudStyles-module--whyChooseDeimosSectionColumn--uQPTt";
export var whyDeimosFlexContainer = "googleCloudStyles-module--whyDeimosFlexContainer--+pz--";
export var contentDescriptionStyle = "googleCloudStyles-module--contentDescriptionStyle--8goqT";
export var certificationMobileImage = "googleCloudStyles-module--certificationMobileImage--oErWz";
export var helpContentSection = "googleCloudStyles-module--helpContentSection--8ADSN";
export var otherCloudServicesHyperLink = "googleCloudStyles-module--otherCloudServicesHyperLink--o5SRx";
export var helpContentSectionHyperlink = "googleCloudStyles-module--helpContentSectionHyperlink--0KcuP";
export var cloudServicesHyperlink = "googleCloudStyles-module--cloudServicesHyperlink--DzAFK";
export var carouselBlock = "googleCloudStyles-module--carouselBlock--AtVbv";
export var cloudServicesSection = "googleCloudStyles-module--cloudServicesSection--jYuu1";
export var cloudServicesSectionHeader = "googleCloudStyles-module--cloudServicesSectionHeader--N2UeF";
export var cloudServicesInner = "googleCloudStyles-module--cloudServicesInner--MsUdc";
export var cloudServicesInnerItems = "googleCloudStyles-module--cloudServicesInnerItems--SV1La";
export var strategicPartnershipWrapper = "googleCloudStyles-module--strategicPartnershipWrapper--KNL-X";
export var strategicButtonStyles = "googleCloudStyles-module--strategicButtonStyles--lRuhh";
export var strategicIconContainer = "googleCloudStyles-module--strategicIconContainer--PiIMb";
export var accordionSection = "googleCloudStyles-module--accordionSection--j01KR";
export var certificationImageDesktop = "googleCloudStyles-module--certificationImageDesktop--vb0DK";